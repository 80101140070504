import React, { Fragment } from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Table,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile,
  isTablet,
  isIOS,
  isBrowser,
} from "react-device-detect";
import { useState, useEffect } from "react";
import aboutUsBase from "../../../../assets/img/about-us-base-with-text.jpg";
import Chairman from "../../../../assets/img/trustees/Chairman.jpg";
import AmeliaTrustee from "../../../../assets/img/trustees/Amelia-Trustee.jpg";
import President from "../../../../assets/img/trustees/President.jpg";
import SecretaryTrustee from "../../../../assets/img/trustees/Secretary.JPG";
import ViceChairman from "../../../../assets/img/trustees/Vice-Chairman.jpg";
import VictoriaTrustee from "../../../../assets/img/trustees/Victoria-Trustee.jpg";
import Treasurer from "../../../../assets/img/trustees/Cecilia-Treasurer.jpg";
import aboutUsBaseMobile from "../../../../assets/img/about-us-base-mobile.jpg";
import BaseFooter from "../../../../assets/img/base-footer.jpg";
import * as FaIcons from "react-icons/fa";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import { dynamicAboutUsActions } from "../../../../services/DynamicAboutUsServices";
import { notifyError } from "../../../../services/Toaster";

const DynamicAboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const toggle = () => setIsOpen(!isOpen);
  const [type, setType] = useState("");
  const [openAchievementModal, setOpenAchievementModal] = useState(false);

  useEffect(() => {
    document.title = "ABOUT US | San Antonio de Padua College";
  }, []);

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicAboutUsActions.getCurrentTemplate(
      setAboutUsData,
      aboutUsData,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      aboutUsBase,
      Chairman,
      AmeliaTrustee,
      President,
      SecretaryTrustee,
      ViceChairman,
      VictoriaTrustee,
      Treasurer,
      aboutUsBaseMobile,
      BaseFooter
    );
  }, []);

  useEffect(() => {
    if (selectedVersion != "") {
      dynamicAboutUsActions.getSelectedTemplate(
        setAboutUsData,
        aboutUsData,
        setCurrentVersion,
        selectedVersion,
        setLoading,
        setVersionSelection,
        aboutUsBase,
        Chairman,
        AmeliaTrustee,
        President,
        SecretaryTrustee,
        ViceChairman,
        VictoriaTrustee,
        Treasurer,
        aboutUsBaseMobile,
        BaseFooter
      );
    }
  }, [selectedVersion]);

  const KeyCodes = {
    enter: 13,
  };

  const delimiters = [KeyCodes.enter];

  const [aboutUsData, setAboutUsData] = useState({
    mainImageDesktop: aboutUsBase,
    mainImageDesktopFile: "",
    mainImageTablet: aboutUsBase,
    mainImageTabletFile: "",
    mainImageMobile: aboutUsBaseMobile,
    mainImageMobileFile: "",
    mainBaseImage: BaseFooter,
    mainBaseImageFile: "",
    mainTitle: "ABOUT US",
    mainDescription:
      "Located south of Manila is the town of Pila in Laguna – Known for its rich history and well-presented historical structural designs. Pila is the seat of the old Tagalog civilization, it is here where the San Antonio de Padua College (SAPC) was established in 1979 by a group of successful entrepreneurs and professionals who believed that poverty is not a hindrance to education. Thus, the institution was founded on a strong sense of corporate social responsibility of the founders. The San Antonio de Padua College was named in honor of the patron saint of the town. Saint Anthony of Padua whose feast day falls on the 13th of June. The school became San Antonio de Padua College Foundation, Inc. in the year 2002. Over the years, SAPC has distinguished itself in academics, culture, and the arts, sports, and entrepreneurship.",
    achievements: [
      {
        title: "Some of its award-winning achievements are :",
        data: [
          "National Essay Writing Contest Population Education Quiz Bee",
          "Jose P. Laurel Centennial Oratorical Contest recipient for three consecutive years of the Chief Girl Scout Award",
          "Four-time volleyball Southern Tagalog Region Champion",
          "Various Palarong Pambansa sports medals",
          "National Regional and Division Awardee of Secondary Schools Press Conference contest",
        ],
      },
      {
        title: "The school’s collegiate level achievements are :",
        data: [
          "Overall Champion in Provincial Skills Olympics (CALABARZON)",
          "Overall Champion in Chefs on Parade Winner of the Accounting Quiz Bee Champion in the Cooking Challenge at the World Food Expo.",
        ],
      },
    ],
    subTitle:
      "The school was founded and continues to be supported by the Figueroa Family of Makati and Pila together with other distinguished individuals who share in their belief and vision.",
    trusteesTitle: "BOARD OF TRUSTEES",
    trustees: [
      {
        image: Chairman,
        imageFile: "",
        name: "Leonardo V. Figueroa, Jr.",
        position: "CHAIRMAN",
      },
      {
        image: ViceChairman,
        imageFile: "",
        name: "Leonardo B. Figueroa, Sr.",
        position: "VICE CHAIRMAN",
      },
      {
        image: President,
        imageFile: "",
        name: "Elisa T. Martinez",
        position: "PRESIDENT",
      },
      {
        image: SecretaryTrustee,
        imageFile: "",
        name: "Ma. Mimie M. Castillo",
        position: "SECRETARY",
      },
      {
        image: Treasurer,
        imageFile: "",
        name: "Cecilia F. Ortiz",
        position: "TREASURER",
      },
      {
        image: AmeliaTrustee,
        imageFile: "",
        name: "Amelia F. Angeles",
        position: "TRUSTEE",
      },
      {
        image: VictoriaTrustee,
        imageFile: "",
        name: "Ma. Victoria A. Merca",
        position: "TRUSTEE",
      },
    ],
    bomTitle: "MEMBERS",
    bomData: [
      "Juliana V. Figueroa",
      "Ernesto B. Figueroa",
      "Liza F. Kravinsky",
      "Dr. Kenneth T. Figueroa",
      "Isagarde Jose D. Angeles",
      "Jaime F. Tiongson",
    ],
    philosophy: {
      title: "SCHOOL PHILOSOPHY",
      description:
        "San Antonio de Padua College strongly believes that education is a holistic experience of learning and living out.  The perfection of knowledge is better appreciated when it is fully realized in a life worth living.  SAPC, therefore, upholds an educational approach that places high regard for individuality and responsible commitment to excellence.  In keeping with this philosophy, the school is bound to carry out its mission as well as achieve its vision of nurturing generations of dynamic, creative and productive members of society.",
    },
    mission: {
      title: "MISSION",
      description:
        "We are and will always be an institution of learning for integrated basic, tertiary, and technical education and training that actively engages the family in pursuit of lifelong independent learning, critical and creative thinking and technological fluency. We are and will always contribute to nation-building and economic development through relevant researches and pro-active extension services geared towards the improvement of the environment and the quality of life of the community we serve. We are and will always demonstrate positive values and beliefs as well as love for country through the virtues of charity, humility and patience.",
    },
    vision: {
      title: "VISION",
      description:
        "We are an institution of learning that fosters the role of the family as we work together in preparing learners to be values-ready, life-ready, work-ready and world-ready.",
    },
    coreValues: {
      title: "CORE VALUES",
      description:
        "To effectively carry out its goals, SAPC as a community commits to the cultivation of the following character and values:",
    },
    coreValuesData: [
      {
        title: "STEWARDSHIP",
        description:
          "Fostering learning and high achievement characterized by social engagement, mutual respect, care and concern for all the students, family, community and environment.",
      },
      {
        title: "ACCOUNTABILITY",
        description:
          "Developing strong work ethic through honesty, integrity, authenticity, and confidence wherein each individual takes responsibility for his words, actions and deeds.",
      },
      {
        title: "DISCIPLINE",
        description:
          "Strengthening moral character by instilling discipline through mutual respect, along with the virtues of charity, humility and patience.",
      },
      {
        title: "EXCELLENCE",
        description:
          "Committing oneself in elevating his/her potentials through independent learning, research and inquiry, creativity, and use of new technologies.",
      },
    ],
  });

  const [images, setImages] = useState({
    mainImageDesktop: "",
    mainImageTablet: "",
    mainImageMobile: "",
    mainBaseImage: "",
  });
  const [changeModal, setChangeModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const acceptedImageExtentions = ["jpg", "jpeg", "png", "gif"];

  const handleChange = (e) => {
    setType("");

    if (!changeModal) {
      setType(e);
      setChangeModal(!changeModal);
    } else {
      setChangeModal(!changeModal);
    }
  };

  const [achievements, setAchievements] = useState(aboutUsData.achievements);

  const handleRemoveAchievement = (i) => {
    let a = achievements;
    let newAchievements = a.filter((b, index) => {
      return index != i;
    });

    setAchievements(newAchievements);
  };

  const blankAchievement = {
    title: "",
    data: [],
    index: "",
  };
  const [action, setAction] = useState("");
  const [temporaryNewAchievement, setTemporarynewAchievement] =
    useState(blankAchievement);

  const toggleAchievementModal = (act) => {
    setTemporarynewAchievement(blankAchievement);
    setAction("");
    if (!openAchievementModal) {
      setAction(act);
      setOpenAchievementModal(!openAchievementModal);
    } else {
      setOpenAchievementModal(!openAchievementModal);
    }
  };

  const handleDeleteTemporaryAchievements = (i) => {
    const filteredTags = temporaryNewAchievement.data.filter(
      (tag, index) => index !== i
    );
    setTemporarynewAchievement({
      ...temporaryNewAchievement,
      data: filteredTags,
    });
  };

  const handleAdditionTemporaryAchievements = (tag) => {
    let temporary = temporaryNewAchievement.data;
    temporary.push(tag);
    setTemporarynewAchievement({ ...temporaryNewAchievement, data: temporary });
  };

  const handleDragTemporaryAchievements = (tag, currPos, newPos) => {
    const newTags = temporaryNewAchievement.data.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTemporarynewAchievement({ ...temporaryNewAchievement, data: newTags });
  };

  const handleSaveNewAchievement = () => {
    let achs = achievements;
    let tempData = temporaryNewAchievement;
    let tempDataArr = temporaryNewAchievement.data.map((d) => {
      return d.text;
    });
    tempData.data = tempDataArr;

    if (action == "add") {
      achs.push(tempData);

      setAchievements(achs);
      setOpenAchievementModal(!openAchievementModal);
    }

    if (action == "edit") {
      let newAchs = achs.map((a, i) => {
        if (tempData.index == i) {
          a = tempData;
          return a;
        } else {
          return a;
        }
      });
      setAchievements(newAchs);
      setOpenAchievementModal(!openAchievementModal);
    }

    setTemporarynewAchievement(blankAchievement);
  };

  const handleEditAchievement = (i, title, data, act) => {
    setAction("");
    let tempData = data.map((d) => {
      return { id: d, text: d };
    });
    setAction(act);
    setTemporarynewAchievement({
      ...temporaryNewAchievement,
      title: title,
      data: tempData,
      index: i,
    });
    setOpenAchievementModal(!openAchievementModal);
  };

  const handleChangeMissionVision = (e, f) => {
    if (type == "Philosophy") {
      let data = {
        title: f == "title" ? e : aboutUsData.philosophy.title,
        description: f == "desc" ? e : aboutUsData.philosophy.description,
      };

      setAboutUsData({ ...aboutUsData, philosophy: data });
    }

    if (type == "Mission") {
      let data = {
        title: f == "title" ? e : aboutUsData.mission.title,
        description: f == "desc" ? e : aboutUsData.mission.description,
      };

      setAboutUsData({ ...aboutUsData, mission: data });
    }

    if (type == "Vision") {
      let data = {
        title: f == "title" ? e : aboutUsData.vision.title,
        description: f == "desc" ? e : aboutUsData.vision.description,
      };

      setAboutUsData({ ...aboutUsData, vision: data });
    }

    if (type == "Core Values") {
      let data = {
        title: f == "title" ? e : aboutUsData.coreValues.title,
        description: f == "desc" ? e : aboutUsData.coreValues.description,
      };

      setAboutUsData({ ...aboutUsData, coreValues: data });
    }
  };

  let blankCoreValue = {
    title: "",
    description: "",
    index: "",
  };
  const [coreValuesModal, setCoreValuesModal] = useState(false);
  const [coreValuesData, setCoreValuesData] = useState(
    aboutUsData.coreValuesData
  );
  const [tempCoreValue, setTempCoreValue] = useState(blankCoreValue);

  const toggleCoreValuesModal = (act) => {
    setAction("");
    setTempCoreValue(blankCoreValue);
    if (!coreValuesModal) {
      setAction(act);
      setCoreValuesModal(!coreValuesModal);
    } else {
      setCoreValuesModal(!coreValuesModal);
    }
  };

  const handleSaveCoreValue = () => {
    let valuesArr = coreValuesData;
    let tempValue = tempCoreValue;
    if (action == "add") {
      valuesArr.push(tempValue);
      setCoreValuesData(valuesArr);
    }

    if (action == "edit") {
      let arr = valuesArr.map((a, i) => {
        if (tempValue.index == i) {
          a = tempValue;
          return a;
        } else {
          return a;
        }
      });
      setCoreValuesData(arr);
    }

    setCoreValuesModal(!coreValuesModal);
    setTempCoreValue(blankCoreValue);
  };

  const handleEditCoreValue = (i, title, description, act) => {
    setAction("");
    setAction(act);
    setTempCoreValue({
      ...tempCoreValue,
      title: title,
      description: description,
      index: i,
    });
    setCoreValuesModal(!coreValuesModal);
  };

  const handleUpdateCoreValuesList = () => {
    setAboutUsData({ ...aboutUsData, coreValuesData: coreValuesData });
    handleChange(!changeModal);
  };

  const blankMembers = {
    title: "",
    members: [],
  };
  const [temporaryMembers, setTemporaryMembers] = useState(blankMembers);

  useEffect(() => {
    if (type == "Members") {
      let membersArr = aboutUsData.bomData.map((d) => {
        return { id: d, text: d };
      });

      setTemporaryMembers({ ...temporaryMembers, members: membersArr });
    }
  }, [type]);

  const handleDeleteMembers = (i) => {
    const filteredTags = temporaryMembers.members.filter(
      (tag, index) => index !== i
    );
    setTemporaryMembers({ ...temporaryMembers, members: filteredTags });
  };

  const handleAddMembers = (tag) => {
    let temporary = temporaryMembers.members;
    temporary.push(tag);
    setTemporaryMembers({ ...temporaryMembers, members: temporary });
  };

  const handleDragMembers = (tag, currPos, newPos) => {
    const newTags = temporaryMembers.members.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTemporaryMembers({ ...temporaryMembers, members: newTags });
  };

  const handleUpdateMembers = () => {
    let membersArr = temporaryMembers.members.map((mem) => {
      return mem.text;
    });
    setAboutUsData({ ...aboutUsData, bomData: membersArr });
  };

  const blankTrustee = {
    image: "",
    imageFile: "",
    name: "",
    position: "",
    index: "",
  };
  const [tempTrustees, setTempTrustees] = useState(aboutUsData.trustees);
  const [openTrusteesModal, setOpenTrusteesModal] = useState(false);
  const [tempTrustee, setTempTrustee] = useState(blankTrustee);

  // useEffect(()=>{
  //     if(type == "Board of Trustees"){
  //         let trusteesArr = aboutUsData.trustees.map(d=>{
  //             return {image: d.image, imageFile: "", name: d.name, position: d.position}
  //         });

  //         setTempTrustees(trusteesArr)
  //     }
  // }, [type])

  const toggleTrusteeModal = (act, index, trustee) => {
    setAction("");
    setTempTrustee(blankTrustee);
    if (!openTrusteesModal) {
      setAction(act);
      if (act == "edit") {
        setTempTrustee({
          ...tempTrustee,
          image: trustee.image,
          name: trustee.name,
          position: trustee.position,
          index: index,
        });
      }
      setOpenTrusteesModal(!openTrusteesModal);
    } else {
      setOpenTrusteesModal(!openTrusteesModal);
    }
  };

  const handleSaveTrustee = () => {
    let tempTs = tempTrustees;
    if (action == "add") {
      tempTs.push(tempTrustee);
      setTempTrustees(tempTs);
    }

    if (action == "edit") {
      let newTempTsArr = tempTs.map((d, i) => {
        if (tempTrustee.index == i) {
          d = tempTrustee;
          return d;
        } else {
          return d;
        }
      });

      setTempTrustees(newTempTsArr);
    }

    setOpenTrusteesModal(!openTrusteesModal);
  };

  const handleSaveNewTrustees = () => {
    setAboutUsData({ ...aboutUsData, trustees: tempTrustees });
    handleChange(false);
  };

  const handleUploadImage = async (e, data) => {
    setImageError(false);
    let extension = e[0].name.split(".")[1];
    if (acceptedImageExtentions.includes(extension)) {
      if (data == "desktop") {
        setImages({ ...images, mainImageDesktop: e[0] });
        setAboutUsData({
          ...aboutUsData,
          mainImageDesktop: URL.createObjectURL(e[0]),
          mainImageDesktopFile: e[0],
        });
      } else if (data == "tablet") {
        setImages({ ...images, mainImageTablet: e[0] });
        setAboutUsData({
          ...aboutUsData,
          mainImageTablet: URL.createObjectURL(e[0]),
          mainImageTabletFile: e[0],
        });
      } else if (data == "mobile") {
        setImages({ ...images, mainImageMobile: e[0] });
        setAboutUsData({
          ...aboutUsData,
          mainImageMobile: URL.createObjectURL(e[0]),
          mainImageMobileFile: e[0],
        });
      } else if (data == "baseImage") {
        setImages({ ...images, mainBaseImage: e[0] });
        setAboutUsData({
          ...aboutUsData,
          mainBaseImage: URL.createObjectURL(e[0]),
          mainBaseImageFile: e[0],
        });
      } else if (data == "trustee") {
        let iFile = {
          type: "mainImageDesktop",
          filename: e[0].name,
          document: await convertBase64(e[0]),
        };
        setTempTrustee({
          ...tempTrustee,
          image: URL.createObjectURL(e[0]),
          imageFile: iFile,
        });
      }
    } else {
      notifyError("Invalid file type for image");
      setImageError(true);
    }
  };

  const handleDeleteData = (i, from) => {
    if (from == "trustee") {
      let tempTsArr = tempTrustees;
      let deleted = tempTsArr.filter((d, key) => {
        return i != key;
      });
      setTempTrustees(deleted);
    } else if (from == "coreValues") {
      let tempCvArr = coreValuesData;
      let deleted = tempCvArr.filter((d, key) => {
        return i != key;
      });
      setCoreValuesData(deleted);
    } else if (from == "achievement") {
      let tempAArr = achievements;
      let deleted = tempAArr.filter((d, key) => {
        return i != key;
      });
      setAchievements(deleted);
    }
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicAboutUsActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  const handleSaveTemplate = async () => {
    setButtonSpinner(true);

    if (aboutUsData.mainImageDesktopFile) {
      let mainImageDesktopFile = {
        type: "mainImageDesktop",
        filename: aboutUsData.mainImageDesktopFile.name,
        document: await convertBase64(aboutUsData.mainImageDesktopFile),
      };
      aboutUsData.mainImageDesktopFile = mainImageDesktopFile;
    }

    if (aboutUsData.mainImageTabletFile) {
      let mainImageTabletFile = {
        type: "mainImageDesktop",
        filename: aboutUsData.mainImageTabletFile.name,
        document: await convertBase64(aboutUsData.mainImageTabletFile),
      };
      aboutUsData.mainImageTabletFile = mainImageTabletFile;
    }

    if (aboutUsData.mainImageMobileFile) {
      let mainImageMobileFile = {
        type: "mainImageDesktop",
        filename: aboutUsData.mainImageMobileFile.name,
        document: await convertBase64(aboutUsData.mainImageMobileFile),
      };
      aboutUsData.mainImageMobileFile = mainImageMobileFile;
    }

    if (aboutUsData.mainBaseImageFile) {
      let mainBaseImageFile = {
        type: "mainImageDesktop",
        filename: aboutUsData.mainBaseImageFile.name,
        document: await convertBase64(aboutUsData.mainBaseImageFile),
      };
      aboutUsData.mainBaseImageFile = mainBaseImageFile;
    }

    let main = {
      mainImageDesktop: aboutUsData.mainImageDesktop,
      mainImageDesktopFile: aboutUsData.mainImageDesktopFile,
      mainImageTablet: aboutUsData.mainImageTablet,
      mainImageTabletFile: aboutUsData.mainImageTabletFile,
      mainImageMobile: aboutUsData.mainImageMobile,
      mainImageMobileFile: aboutUsData.mainImageMobileFile,
      mainBaseImage: aboutUsData.mainBaseImage,
      mainBaseImageFile: aboutUsData.mainBaseImageFile,
      mainTitle: aboutUsData.mainTitle,
      mainDescription: aboutUsData.mainDescription,
      achievements: aboutUsData.achievements,
      subTitle: aboutUsData.subTitle,
    };

    let mission_vision = {
      philosophy: {
        title: aboutUsData.philosophy.title,
        description: aboutUsData.philosophy.description,
      },
      mission: {
        title: aboutUsData.mission.title,
        description: aboutUsData.mission.description,
      },
      vision: {
        title: aboutUsData.vision.title,
        description: aboutUsData.vision.description,
      },
      coreValues: {
        title: aboutUsData.vision.title,
        description: aboutUsData.vision.description,
      },
      coreValuesData: aboutUsData.coreValuesData,
    };

    let trustees_members = {
      bomTitle: aboutUsData.bomTitle,
      bomData: aboutUsData.bomData,
      trusteesTitle: aboutUsData.trusteesTitle,
      trustees: aboutUsData.trustees,
    };

    let dataToSubmit = {
      main: JSON.stringify(main),
      mission_vision: JSON.stringify(mission_vision),
      trustees_members: JSON.stringify(trustees_members),
    };

    dynamicAboutUsActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className="p-4">
      <UncontrolledTooltip placement="top" target="mainTitle">
        <h5>Click to Update</h5>
      </UncontrolledTooltip>
      {/* <Slide left> */}
      <Row>
        <Col md="6" xs="12" className="my-4">
          <Select
            options={versionSelection}
            name="version"
            className="w-25"
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
        <Col
          md="12"
          xs="12"
          className="px-0 w-100 justify-content-center align-items-center d-flex"
          id="mainImage"
          style={{ cursor: "pointer" }}
          onClick={() => handleChange("Main Image")}
        >
          {isDesktop ? (
            <img
              src={aboutUsData.mainImageDesktop}
              className="w-100"
              style={{ height: "95vh", objectFit: "cover" }}
            />
          ) : isTablet ? (
            <img
              src={aboutUsData.mainImageTablet}
              className="w-100"
              style={{ height: "50vh", objectFit: "cover" }}
            />
          ) : (
            <img
              src={aboutUsData.mainImageMobile}
              className="w-100 image-mobile"
              style={{ height: "100vh", objectFit: "cover" }}
            />
          )}
        </Col>
        <UncontrolledTooltip placement="top" target="mainImage">
          <h5>Click to Update</h5>
        </UncontrolledTooltip>
      </Row>
      {/* </Slide> */}
      <div
        style={{
          backgroundImage: `url(${aboutUsData.mainBaseImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          minHeight: "100vh",
          backgroundSize: "100%",
        }}
      >
        <Row className="text-secondary mb-5">
          <Col
            md="2"
            xs="12"
            className="about-border justify-content-center align-items-center d-flex"
          >
            <h3
              id="mainTitle"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Main Title")}
              className={
                isDesktop
                  ? "vertical-flip font-weight-bolder"
                  : isTablet
                  ? "vertical-flip font-weight-bolder"
                  : "mt-5 font-weight-bolder"
              }
            >
              {aboutUsData.mainTitle || "<No Content>"}
            </h3>
            <UncontrolledTooltip placement="top" target="mainTitle">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>
          </Col>
          <Col
            md="9"
            xs="12"
            className={
              isDesktop
                ? "mx-auto h-100 w-100 justify-content-center align-items-center mt-5"
                : "mt-5 justify-content-center align-items-center px-5"
            }
          >
            <p
              className="mb-5"
              id="mainDescription"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Main Description")}
            >
              {aboutUsData.mainDescription || "<No Content>"}
            </p>
            <UncontrolledTooltip placement="top" target="mainDescription">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>
            {isDesktop ? (
              <>
                <div
                  id="achievements"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChange("Achievements")}
                >
                  {aboutUsData.achievements.length >= 1 ? (
                    aboutUsData.achievements.map((achievement, key) => {
                      return (
                        <Fragment key={key}>
                          <p className="font-weight-bolder">
                            {achievement.title || "<No Content>"}
                          </p>
                          <ul className="mb-5">
                            {achievement.data.length >= 1 ? (
                              achievement.data.map((list, i) => {
                                return (
                                  <Fragment key={i}>
                                    <li>{list}</li>
                                  </Fragment>
                                );
                              })
                            ) : (
                              <li>{"<No Content>"}</li>
                            )}
                          </ul>
                        </Fragment>
                      );
                    })
                  ) : (
                    <p>{"<No Content>"}</p>
                  )}
                </div>
                <UncontrolledTooltip placement="top" target="achievements">
                  <h5>Click to Update</h5>
                </UncontrolledTooltip>

                <p
                  id="subTitle"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChange("Sub Title")}
                  className="font-weight-bolder"
                >
                  {aboutUsData.subTitle || "<No Content>"}
                </p>
                <UncontrolledTooltip placement="top" target="subTitle">
                  <h5>Click to Update</h5>
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                <div>
                  {aboutUsData.achievements.length >= 1 ? (
                    aboutUsData.achievements.map((achievement, key) => {
                      return (
                        <Fragment key={key}>
                          <p className="font-weight-bolder">
                            {achievement.title}
                          </p>
                          {achievement.data.map((list, i) => {
                            return (
                              <Fragment key={i}>
                                <p>{list}</p>
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    })
                  ) : (
                    <p>{"<No Content>"}</p>
                  )}
                </div>
                <p className="font-weight-bolder">{aboutUsData.subTitle}</p>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col
            md="2"
            xs="12"
            className="about-border justify-content-center align-items-center d-flex bg-warning text-white"
          >
            <h3
              id="trusteesTitle"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Trustees Title")}
              className={
                isDesktop
                  ? "vertical-flip font-weight-bolder"
                  : isTablet
                  ? "vertical-flip font-weight-bolder"
                  : "mt-2 font-weight-bolder text-center"
              }
            >
              {aboutUsData.trusteesTitle || "<No Content>"}
            </h3>
            <UncontrolledTooltip placement="top" target="trusteesTitle">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>
          </Col>

          <Col
            md="4"
            xs="12"
            className="mt-5 font-weight-bolder text-secondary"
          >
            <div
              id="trustees"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Board of Trustees")}
            >
              {aboutUsData.trustees.length >= 1 ? (
                aboutUsData.trustees.map((trustee, i) => {
                  return (
                    <Row
                      key={i}
                      className="ml-1 justify-content-center align-items-center"
                    >
                      <Col>
                        <img
                          src={trustee.image}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          className="mb-4"
                        />
                      </Col>
                      <Col>
                        <p>
                          {trustee.name}
                          <br />
                          {trustee.position}
                        </p>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <p>{"<No Content>"}</p>
              )}
              <UncontrolledTooltip placement="top" target="trustees">
                <h5>Click to Update</h5>
              </UncontrolledTooltip>
            </div>

            <Row
              className={
                isDesktop
                  ? "ml-1 justify-content-center align-items-center text-white members-overlay ml-3"
                  : "ml-1 justify-content-center align-items-center"
              }
            >
              <Col
                id="members"
                style={{ cursor: "pointer" }}
                onClick={() => handleChange("Members")}
                xs="12"
                className="py-2 text-center"
              >
                <div className="members-opacity">
                  <p>{aboutUsData.bomTitle || "<No Content>"}</p>
                  {aboutUsData.bomData.length >= 1 ? (
                    aboutUsData.bomData.map((data, key) => {
                      return (
                        <Fragment key={key}>
                          <p className="mb-0">{data}</p>
                        </Fragment>
                      );
                    })
                  ) : (
                    <p>{"<No Content>"}</p>
                  )}
                </div>
              </Col>
              <UncontrolledTooltip placement="top" target="members">
                <h5>Click to Update</h5>
              </UncontrolledTooltip>
            </Row>
          </Col>

          <Col
            md="6"
            xs="12"
            className="mx-auto h-100 w-100 justify-content-center align-items-center pt-5 text-center content-border bg-light"
          >
            <div
              id="philosophy"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Philosophy")}
            >
              <h4 className="font-weight-bolder text-center mx-5">
                {aboutUsData.philosophy.title || "<No Content>"}
              </h4>
              <p className="text-justify mx-5">
                {aboutUsData.philosophy.description || "<No Content>"}
              </p>
            </div>
            <UncontrolledTooltip placement="top" target="philosophy">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>

            <div
              id="mission"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Mission")}
            >
              <h4 className="font-weight-bolder text-center mx-5">
                {aboutUsData.mission.title || "<No Content>"}
              </h4>
              <p className="text-justify mx-5">
                {aboutUsData.mission.description || "<No Content>"}
              </p>
            </div>
            <UncontrolledTooltip placement="top" target="mission">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>

            <div
              id="vision"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Vision")}
            >
              <h4 className="font-weight-bolder text-center mx-5">
                {aboutUsData.vision.title || "<No Content>"}
              </h4>
              <p className="text-justify mx-5">
                {aboutUsData.vision.description || "<No Content>"}
              </p>
            </div>
            <UncontrolledTooltip placement="top" target="vision">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>

            <div
              id="coreValues"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Core Values")}
            >
              <h4 className="font-weight-bolder text-center mx-5">
                {aboutUsData.coreValues.title || "<No Content>"}
              </h4>
              <p className="text-justify mx-5 mb-3">
                {aboutUsData.coreValues.description || "<No Content>"}
              </p>
            </div>
            <UncontrolledTooltip placement="top" target="coreValues">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>
            <div
              id="coreValuesData"
              style={{ cursor: "pointer" }}
              onClick={() => handleChange("Core Values List")}
            >
              {coreValuesData.length >= 1 ? (
                coreValuesData.map((d, i) => {
                  return (
                    <Fragment key={i}>
                      <p className="text-justify mx-5">
                        <strong>{d.title + ". "}</strong>
                        {d.description}
                      </p>
                    </Fragment>
                  );
                })
              ) : (
                <p>{"<No Content>"}</p>
              )}
            </div>
            <UncontrolledTooltip placement="top" target="coreValuesData">
              <h5>Click to Update</h5>
            </UncontrolledTooltip>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={changeModal}
        toggle={handleChange}
        size="xl"
        backdrop="static"
      >
        <ModalHeader>Update {type}</ModalHeader>
        <ModalBody>
          {type == "Main Image" ? (
            <>
              <Label>Desktop</Label>
              <Input
                type="file"
                className="mb-3"
                onChange={(e) => handleUploadImage(e.target.files, "desktop")}
              />

              <Label>Tablet</Label>
              <Input
                type="file"
                className="mb-3"
                onChange={(e) => handleUploadImage(e.target.files, "tablet")}
              />

              <Label>Mobile</Label>
              <Input
                type="file"
                className="mb-3"
                onChange={(e) => handleUploadImage(e.target.files, "mobile")}
              />

              <Label>Base Image</Label>
              <Input
                type="file"
                onChange={(e) => handleUploadImage(e.target.files, "baseImage")}
              />
            </>
          ) : type == "Main Description" ? (
            <Input
              defaultValue={aboutUsData.mainDescription}
              type="textarea"
              style={{ height: "500px" }}
              onChange={(e) =>
                setAboutUsData({
                  ...aboutUsData,
                  mainDescription: e.target.value,
                })
              }
            />
          ) : type == "Main Title" ? (
            <Input
              defaultValue={aboutUsData.mainTitle}
              type="text"
              onChange={(e) =>
                setAboutUsData({ ...aboutUsData, mainTitle: e.target.value })
              }
            />
          ) : type == "Sub Title" ? (
            <Input
              defaultValue={aboutUsData.subTitle}
              type="textarea"
              style={{ height: "500px" }}
              onChange={(e) =>
                setAboutUsData({ ...aboutUsData, subTitle: e.target.value })
              }
            />
          ) : type == "Trustees Title" ? (
            <>
              <Input
                defaultValue={aboutUsData.trusteesTitle}
                type="text"
                onChange={(e) =>
                  setAboutUsData({
                    ...aboutUsData,
                    trusteesTitle: e.target.value,
                  })
                }
              />
            </>
          ) : type == "Philosophy" ? (
            <>
              <Label>Title</Label>
              <Input
                defaultValue={aboutUsData.philosophy.title}
                type="text"
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "title")
                }
              />

              <Label className="mt-5">Description</Label>
              <Input
                defaultValue={aboutUsData.philosophy.description}
                type="textarea"
                style={{ height: "300px" }}
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "desc")
                }
              />
            </>
          ) : type == "Mission" ? (
            <>
              <Label>Title</Label>
              <Input
                defaultValue={aboutUsData.mission.title}
                type="text"
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "title")
                }
              />

              <Label className="mt-5">Description</Label>
              <Input
                defaultValue={aboutUsData.mission.description}
                type="textarea"
                style={{ height: "300px" }}
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "desc")
                }
              />
            </>
          ) : type == "Vision" ? (
            <>
              <Label>Title</Label>
              <Input
                defaultValue={aboutUsData.vision.title}
                type="text"
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "title")
                }
              />

              <Label className="mt-5">Description</Label>
              <Input
                defaultValue={aboutUsData.vision.description}
                type="textarea"
                style={{ height: "300px" }}
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "desc")
                }
              />
            </>
          ) : type == "Members" ? (
            <>
              <Label>Title</Label>
              <Input
                defaultValue={aboutUsData.bomTitle}
                type="text"
                onChange={(e) =>
                  setAboutUsData({ ...aboutUsData, bomTitle: e.target.value })
                }
              />

              <Label className="mt-5">Members</Label>
              <ReactTags
                tags={temporaryMembers.members}
                handleDelete={handleDeleteMembers}
                handleAddition={handleAddMembers}
                handleDrag={handleDragMembers}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />

              <Button
                className="mt-5"
                color="success"
                onClick={handleUpdateMembers}
              >
                Update
              </Button>
            </>
          ) : type == "Core Values" ? (
            <>
              <Label>Title</Label>
              <Input
                defaultValue={aboutUsData.coreValues.title}
                type="text"
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "title")
                }
              />

              <Label className="mt-5">Description</Label>
              <Input
                defaultValue={aboutUsData.coreValues.description}
                type="textarea"
                style={{ height: "300px" }}
                onChange={(e) =>
                  handleChangeMissionVision(e.target.value, "desc")
                }
              />
            </>
          ) : type == "Core Values List" ? (
            <>
              <Table striped bordered className="mt-3">
                <thead className="text-center">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coreValuesData.map((value, i) => {
                    return (
                      <tr key={i}>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {i + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {value.title}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {value.description}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center">
                            <Button className="text-center mr-2">
                              {
                                <FaIcons.FaEdit
                                  style={{ fontSize: "1rem" }}
                                  onClick={() =>
                                    handleEditCoreValue(
                                      i,
                                      value.title,
                                      value.description,
                                      "edit"
                                    )
                                  }
                                />
                              }
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => handleDeleteData(i, "coreValues")}
                            >
                              X
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                color="secondary"
                className="mt-2 mr-2 text-right"
                onClick={() => toggleCoreValuesModal("add")}
              >
                Add +
              </Button>
              <Button
                color="success"
                className="mt-2 text-right"
                onClick={handleUpdateCoreValuesList}
              >
                Update
              </Button>
            </>
          ) : type == "Achievements" ? (
            <>
              <Table striped bordered style={{ verticalAlign: "middle" }}>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Title</th>
                    <th className="text-center">Achievements</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {achievements.map((ach, i) => {
                    return (
                      <Fragment key={i}>
                        <tr key={i}>
                          <td
                            className="text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {i + 1}
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            {ach.title}
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <ul>
                              {ach.data.map((d, key) => {
                                return (
                                  <Fragment key={key}>
                                    <li>{d}</li>
                                  </Fragment>
                                );
                              })}
                            </ul>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center">
                              <Button
                                className="text-center mr-2"
                                onClick={() =>
                                  handleEditAchievement(
                                    i,
                                    ach.title,
                                    ach.data,
                                    "edit"
                                  )
                                }
                              >
                                {
                                  <FaIcons.FaEdit
                                    style={{ fontSize: "1rem" }}
                                  />
                                }
                              </Button>
                              <Button
                                color="danger"
                                onClick={() =>
                                  handleDeleteData(i, "achievement")
                                }
                              >
                                X
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => toggleAchievementModal("add")}
              >
                Add +
              </Button>
              <Button
                color="success"
                onClick={() =>
                  setAboutUsData({ ...aboutUsData, achievements: achievements })
                }
              >
                Save{" "}
              </Button>
            </>
          ) : type == "Board of Trustees" ? (
            <>
              <div className="my-3">
                <Button
                  color="secondary"
                  className="mr-2"
                  onClick={() => toggleTrusteeModal("add", "", "")}
                >
                  Add +
                </Button>
                <Button
                  color="success"
                  className="my-3"
                  onClick={handleSaveNewTrustees}
                >
                  Update Board of Trustees
                </Button>
              </div>
              <Table striped bordered className="text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tempTrustees.map((trustee, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                        <td style={{ verticalAlign: "middle" }}>
                          <img
                            src={trustee.image}
                            style={{
                              height: "100px",
                              width: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {trustee.name}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {trustee.position}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center text-center justify-content-center">
                            <Button
                              className="text-center mr-2"
                              onClick={() =>
                                toggleTrusteeModal("edit", i, trustee)
                              }
                            >
                              {<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => handleDeleteData(i, "trustee")}
                            >
                              X
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleChange(!changeModal)}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openAchievementModal} backdrop="static" size="lg">
        <ModalHeader toggle={toggleAchievementModal}>
          Add New Achievement
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-2">Title</h5>
          <Input
            type="text"
            value={temporaryNewAchievement.title}
            className="mb-4"
            onChange={(e) =>
              setTemporarynewAchievement({
                ...temporaryNewAchievement,
                title: e.target.value,
              })
            }
          />

          <h5 className="mb-2">Contents</h5>
          <ReactTags
            tags={temporaryNewAchievement.data}
            handleDelete={handleDeleteTemporaryAchievements}
            handleAddition={handleAdditionTemporaryAchievements}
            handleDrag={handleDragTemporaryAchievements}
            inputFieldPosition="top"
            placeholder="Press enter to add new content"
            labelField="text"
            delimiters={delimiters}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveNewAchievement}>
            Save Achievement
          </Button>
          <Button
            onClick={() => setOpenAchievementModal(!openAchievementModal)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={coreValuesModal} backdrop="static" size="lg">
        <ModalHeader toggle={toggleCoreValuesModal}>Add Core Value</ModalHeader>
        <ModalBody>
          <h5 className="mb-2">Title</h5>
          <Input
            type="text"
            value={tempCoreValue.title}
            className="mb-4"
            onChange={(e) =>
              setTempCoreValue({ ...tempCoreValue, title: e.target.value })
            }
          />

          <h5 className="mb-2">Description</h5>
          <Input
            type="textarea"
            style={{ height: "200px" }}
            value={tempCoreValue.description}
            className="mb-4"
            onChange={(e) =>
              setTempCoreValue({
                ...tempCoreValue,
                description: e.target.value,
              })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveCoreValue}>
            Save
          </Button>
          <Button onClick={() => setCoreValuesModal(!coreValuesModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openTrusteesModal} backdrop="static" size="lg">
        <ModalHeader toggle={toggleTrusteeModal}>
          Add New Board of Trustee
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-2">Image</h5>
          <img
            src={tempTrustee.image}
            style={{ height: "100px", width: "100px", objectFit: "cover" }}
          />
          <Input
            type="file"
            className="mb-4"
            onChange={(e) => handleUploadImage(e.target.files, "trustee")}
          />

          <h5 className="mb-2">Name</h5>
          <Input
            type="text"
            value={tempTrustee.name}
            className="mb-4"
            onChange={(e) =>
              setTempTrustee({ ...tempTrustee, name: e.target.value })
            }
          />

          <h5 className="mb-2">Position</h5>
          <Input
            type="text"
            value={tempTrustee.position}
            className="mb-4"
            onChange={(e) =>
              setTempTrustee({ ...tempTrustee, position: e.target.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveTrustee}>
            Save
          </Button>
          <Button onClick={() => setOpenTrusteesModal(!openTrusteesModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DynamicAboutUs;
